export const testMenu = [
  {
    id: 1,
    name: "Lumber Jack Special",
    description:
      "Sausage and hardwood-smoked bacon with two fresh-cracked eggs* cooked-to-order and your choice of  hash browns, home fries or fresh-cut fruit. Served with three hotcakes or two slices of Brioche French toast.",
    calories: 1170,
    category: "breakfast",
    price: 19.99,
    image: "https://i.ibb.co/yFrFyQ1/breakfast-01.jpg",
    options: null,
    qty: 1,
  },
  {
    id: 2,
    name: "Banana Berry Pancakes",
    description:
      "Choice of premium breakfast meat with two fresh-cracked eggs* cooked-to-order and your choice of hash browns, home fries or fresh-cut fruit. Served with three blueberry hotcakes topped with fresh bananas.",
    calories: 1270,
    category: "breakfast",
    price: 17.99,
    image: "https://i.ibb.co/kGCb5m3/breakfast-02.jpg",
    options: null,
    qty: 1,
  },
  {
    id: 3,
    name: "The Hog Wild Special",
    description:
      "Go hog wild for our farm-famous meats! Two strips of crispy bacon, two sausage patties, two sausage links, one slice of hickory-smoked ham, two fresh-cracked eggs* cooked to order, one hotcake and one slice of brioche French toast. Plus, your choice of golden brown home fries or hash browns.",
    calories: 2000,
    category: "breakfast",
    price: 29.99,
    image: "https://i.ibb.co/TPSGSPG/breakfast-03.jpg",
    options: null,
    qty: 1,
  },
  {
    id: 4,
    name: "Classic Turkey Club",
    description:
      "A farmer's club sandwich with slow-roasted turkey, bacon, provolone cheese, lettuce, tomato, onion and mayo on toasted multigrain bread. Served with deli pickles.",
    calories: 730,
    category: "lunch",
    price: 12.99,
    image: "https://i.ibb.co/RymFvKz/lunch-01.jpg",
    options: null,
    qty: 1,
  },
  {
    id: 5,
    name: "Bacon Cheese Burger",
    description:
      "100% black Angus beef topped with hardwood-smoked bacon, American cheese, lettuce, tomato, onion and garnished with deli pickles. Served with choice of one or two sides.",
    calories: 840,
    category: "lunch",
    price: 15.99,
    image: "https://i.ibb.co/42qV3xv/lunch-02.jpg",
    options: null,
    qty: 1,
  },
  {
    id: 6,
    name: "Classic Fish Sandwich",
    description:
      "Two Alaska cod fillets with a light, flaky, pub-style batter, cabbage and dill tartar sauce on a brioche bun. Served with French fries and a lemon wedge.",
    calories: 780,
    category: "lunch",
    price: 14.99,
    image: "https://i.ibb.co/pnR4b4t/lunch-03.jpg",
    options: null,
    qty: 1,
  },
  {
    id: 7,
    name: "Steak Tip Dinner",
    description:
      "USDA Choice Beef Steak Tips grilled with sauteed mushrooms, caramelized onions, and topped with green onions. Served with choice of two sides and choice of freshly baked bread.",
    calories: 1640,
    category: "dinner",
    price: 27.99,
    image: "https://i.ibb.co/5KZ8pnq/dinner-01.jpg",
    options: null,
    qty: 1,
  },
  {
    id: 8,
    name: "Country Fried Steak",
    description:
      "Two pieces of lightly breaded tender beef and mashed potatoes smothered with country gravy. Served with green beans.",
    calories: 1450,
    category: "dinner",
    price: 23.99,
    image: "https://i.ibb.co/vVDT1yc/dinner-02.jpg",
    options: null,
    qty: 1,
  },
  {
    id: 9,
    name: "6pcs Family Fried Chicken",
    description:
      "Six hand-breaded all white meat chicken breasts served with 2 family size sides and a dozen freshly baked rolls. Now you can upgrade to a 3-course meal! Serves up to 6.",
    calories: 1460,
    category: "dinner",
    price: 32.99,
    image: "https://i.ibb.co/T4S4p04/dinner-03.jpg",
    options: null,
    qty: 1,
  },
  {
    id: 10,
    name: "Chef's Garden Salad",
    description:
      "Fresh greens topped with diced tomatoes, real cheddar cheese and croutons.",
    calories: 450,
    category: "sides",
    price: 9.99,
    image: "https://i.ibb.co/QNqZSLM/side-01.jpg",
    options: null,
    qty: 1,
  },
  {
    id: 11,
    name: "Chaddar Potato Soup",
    description:
      "Large cuts of potato blended together in a perfectly seasoned, satisfying, sharp cheddar cheese base. Topped with bacon.",
    calories: 450,
    category: "sides",
    price: 11.99,
    image: "https://i.ibb.co/0yVCxbf/side-02.jpg",
    options: null,
    qty: 1,
  },
  {
    id: 12,
    name: "French Fries w/ Black Truffle Oil",
    description:
      "Crispy French fries sliced from Russet potatoes, and sprinkled with black truffle oil.",
    calories: 1020,
    category: "sides",
    price: 9.99,
    image: "https://i.ibb.co/mNhXdXy/side-03.jpg",
    options: null,
    qty: 1,
  },
  {
    id: 13,
    name: "Coca-Cola Classic",
    description: "20 Ounces",
    calories: 170,
    category: "drinks",
    price: 2.99,
    image: "https://i.ibb.co/dQspy3M/drink-06.jpg",
    options: null,
    qty: 1,
  },

  {
    id: 14,
    name: "Cherry Coke",
    description: "20 Ounces",
    calories: 170,
    category: "drinks",
    price: 2.99,
    image: "https://i.ibb.co/ynnCz8N/drink-07.jpg",
    options: null,
    qty: 1,
  },
  {
    id: 15,
    name: "Sprite",
    description: "20 Ounces",
    calories: 130,
    category: "drinks",
    price: 2.99,
    image: "https://i.ibb.co/KyLSf7R/drink-08.webp",
    options: null,
    qty: 1,
  },
  {
    id: 16,
    name: "Diet Coke",
    description: "20 Ounces",
    calories: 130,
    category: "drinks",
    price: 2.99,
    image: "https://i.ibb.co/3McBXny/drink-09.webp",
    options: null,
    qty: 1,
  },
  {
    id: 17,
    name: "Ka'u Hawaiian Coffee",
    description:
      "Ka'u Hawaiian coffee is grown, hand-picked and produced at family farms on the gentle slopes of Mauna Loa in the lush, green Ka'u region of Hawaii's Big Island - not far from the world-famous Kona coffee farms.",
    calories: 0,
    category: "drinks",
    price: 4.99,
    image: "https://i.ibb.co/r7qNbhB/drink-04.jpg",
    options: null,
    qty: 1,
  },
  {
    id: 18,
    name: "Orange Juice",
    description: "16 Ounces of freshly squeezed, orange goodness",
    calories: 210,
    category: "drinks",
    price: 2.99,
    image: "https://i.ibb.co/LZ3RkS6/drink-05.jpg",
    options: null,
    qty: 1,
  },
];
