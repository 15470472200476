export const demoTaxRates = [
  {
    id: 1,
    state: "CT",
    rate_ids: ["txr_1PIf7vKBxDm065CkthEuOugl", "txr_1PIf6nKBxDm065CkCTJFxhpn"],
    total_rate: 0.0735,
  },
  {
    id: 2,
    state: "NH",
    rate_ids: ["txr_1PIf9OKBxDm065CkPj37jtYB"],
    total_rate: 0.085,
  },
  {
    id: 3,
    state: "CA",
    rate_ids: ["txr_1PIfG1KBxDm065CktcLslHaM"],
    total_rate: 0.08625,
  },
  {
    id: 4,
    state: "TX",
    rate_ids: ["txr_1PIfJPKBxDm065CkrpJEU9qn"],
    total_rate: 0.0825,
  },
  {
    id: 5,
    state: "NY",
    rate_ids: ["txr_1PJ1C6KBxDm065CkksmKnqwp"],
    total_rate: 0.08875,
  },
  {
    id: 6,
    state: "MD",
    rate_ids: ["txr_1PJ1CqKBxDm065CkFNlECd8X"],
    total_rate: 0.06,
  },
  {
    id: 7,
    state: "HI",
    rate_ids: ["txr_1PJ1EiKBxDm065CkLTfV9tUu"],
    total_rate: 0.045,
  },
  {
    id: 8,
    state: "DC",
    rate_ids: ["txr_1PJ1GRKBxDm065CkAMvtilF3"],
    total_rate: 0.06,
  },
  {
    id: 9,
    state: "PE",
    rate_ids: ["txr_1PJ1HbKBxDm065Ck38tKh10m"],
    total_rate: 0.15,
  },
  {
    id: 7,
    state: "CO",
    rate_ids: ["txr_1PJ1JnKBxDm065Ckvoi3RNMW"],
    total_rate: 0.029,
  },
];
